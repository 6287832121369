import React, { useState, useEffect } from 'react';
import '../App.css'

import firebaseConfig from '../env/firebase.js';
import { initializeApp } from "firebase/app";
import { getFirestore,doc, collection, addDoc, updateDoc, getDoc, query, where, getDocs, increment, setDoc } from "firebase/firestore"; 


let blocc_id = sessionStorage.getItem('blocc_id')

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
/* eslint-disable */
var DateTime = luxon.DateTime;
var d = DateTime.now().toString() // moment().format()
var today = d.substring(0,10)  // moment().format(); 

let updateMiscs = async (name) => {
    const bloccRef = doc(db, "bloccs", blocc_id, 'insights', today );
    const alltimeRef = doc(db, "bloccs", blocc_id, 'insights', 'alltime');

    try{
        await updateDoc(bloccRef, {
            [`links.${name}`]: increment(1),  
        })
        await updateDoc(alltimeRef, {
            [`links.${name}`]: increment(1)
        })
        console.log('stats updated!')
    }
    catch(e){
        console.log(e)
    }
    
}

const LinkCard = (props) => {
    // console.log(props)
    return(
        <div className ={`linkCard animate__animated animate__fadeIn ${props.transparent? 'transparent':''}`} style={{backgroundColor: props.backgroundColor}}
            onClick={() => {
                updateMiscs(props.Title)
                window.open(props.Link, '_blank')
                
            }}
        >
        
        {/* {image.length > 0 &&
            
            <Image source={{uri: image}} />
        }
    
        {icon.length > 0 &&
            <MaterialIcons name={icon} color='white' size={25} />
        } */}
            {/* <p style={[style.CardTitle,{color:isLightColor(color_type)? 'black':'white'}]}>{title}</p> */}
            <p className='no-margin' style={{color:props.TextColor == 'light'? 'black':'white'}}>{props.Title}</p>
        </div>
 
    )
};

const DescCard = (props) => {
    // console.log('this item props are ',props)

    return(
        <div className={`descCard animate__animated animate__fadeIn ${props.transparent? 'transparent':''}`} style={{backgroundColor: props.backgroundColor}}>
    
            <p className='no-margin leftcentered' style={{color:props.TextColor == 'light'? 'black':'white'}}>{props.Description}</p>
        
        </div>
    )
};

const Anchor = (props) => (
    <div id={props.id} className='w-100 d-flex animate__animated animate__fadeIn' style={{flexDirection:'row',paddingTop:15,paddingLeft:7}}>
        <p style={{marginRight:10,fontSize:17,fontWeight:'500',color:props.TextColor}}>{props.Title}</p>
        <div className='line' style={{backgroundColor: props.TextColor == 'black'? '#02020254':'#ffffff54' }}></div>
    </div>
)

const ImageCard = (props) => (
    <img className='imageCard animate__animated animate__fadeIn' data-bs-toggle="modal" data-bs-target="#fullMedia" onClick={props.onClick} src={props.Source} />
)

const SpotifyCard = (props) => {
    let link = props.Link
    let spotify_id = link.split('https://open.spotify.com/track/')[1].split('?si')[0]
    // console.log('spotify link ==> ', props.Link)
    return(
        <iframe 
            style={{borderRadius:12,marginBottom:15}}
            src={"https://open.spotify.com/embed/track/"+ spotify_id +"?utm_source=generator&theme=" + props.Theme}
            width="100%" 
            height="152" 
            frameBorder="0" allowfullscreen="" 
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy">

        </iframe>
    )
}

let Blocc = (props) => {
    return (
        <>
            {props.Type === 'linkcard' && 
                <LinkCard Title={props.Title} Link={props.Link} backgroundColor={props.backgroundColor} transparent={props.Transparent} TextColor={props.TextColor}/>
            }
    
            {props.Type === 'desccard' && 
                <DescCard Description={props.Description} backgroundColor={props.backgroundColor} transparent={props.Transparent} TextColor={props.TextColor}/>
            }
            
            {(props.Type === 'imagecard') && 
                <ImageCard Source={props.Source} onClick={props.onClick} />
            
            }
    
            {props.Type === 'anchorcard' && 
                <Anchor id={props.id} Title={props.Title}/>
            }

            {props.Type === 'spotifycard' && 
                <SpotifyCard Link={props.Link} Theme={props.Theme}/>
            }

        
        </>
    ) 
} 

   

    export default Blocc