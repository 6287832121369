import React, { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import firebaseConfig from './env/firebase.js';
import { initializeApp } from "firebase/app";
import { getFirestore,doc, collection, addDoc, updateDoc, getDoc, query, where, getDocs, increment, setDoc } from "firebase/firestore"; 

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';



import Blocc from './components/blocc';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const window_ref = window.location.toString()
const urlValue = window_ref.split('/').pop().split(/[?#]/)[0]
const blocc_id = urlValue.length > 2? urlValue : 'goblocc'

console.log('url value ==> ',urlValue,blocc_id)
/* eslint-disable */
const DateTime = luxon.DateTime;
const d = DateTime.now().toString() // moment().format()
const today = d.substring(0,10)  // moment().format(); 


/* eslint-disable */
$(window).scroll(function(){
  const position = $(this).scrollTop();
  
  //console.log('position ==> ',position)
  if(position >= 45) {
    $(".topblock").addClass('animate__fadeOut')
    $(".top-cover").removeClass("d-none").addClass("animate__fadeInDown")
  } 
  else {
    $(".topblock").removeClass("animate__fadeOut").addClass('animate__fadeIn')
    //$(".top-cover").removeClass("animate__fadeInDown").addClass("animate__fadeOutUp d-none")
    $(".top-cover").addClass('d-none')
  }
})


function App() {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const [found, setFound] = useState(false)
  const [bgColor, isColor] = useState(null)
  const [profileimage, isProfileImage] = useState('https://thumbs.dreamstime.com/b/creative-vector-illustration-of-default-avatar-profile-placeholder-isolated-on-background-art-design-grey-photo-blank-template-mo-107388687.jpg')
  const [descr, isDescr] = useState('...')
  const [hasbackground,this_hasBackgroud] = useState(false)
  const [backgroundValue,this_backgroundValue] = useState(null)
  const [structure, isStructure] = useState([])
  const [transparent, isTransparent] = useState(null)
  const [maincolor, isMainColor] = useState(null)

  const [facebook,isFacebook] = useState(null)
  const [twitter,isTwitter] = useState(null)
  const [tiktok,isTiktok] = useState(null)
  const [instagram,isInstagram] = useState(null)

  const [showLinks, isShowLinks] = useState(false)
  const [loaded, isLoaded] = useState(false)
  const [dataloaded, isDataLoaded] = useState(false)

  const [bloccid, isBloccId] = useState(null)

  const [showFullImage, isFullImage] = useState(false)
  const  [currentimage,setImage] = useState('')

  //const [animationParent] = useAutoAnimate()

  const updateStats = async (id,user_id) => {
    // console.log('update blocc with id ===> ',id)
    const userRef = doc(db, "users", user_id);
    await updateDoc(userRef, {
      allviews: increment(1),
      
    });

    const bloccRef = doc(db, "bloccs", id);
    await updateDoc(bloccRef, {
      'stats.views': increment(1),
      'last_view_date': new Date
    });

    // console.log('stats updated!')
  }

  const updateStatsDetailed = async (id) => {
    const bloccRef = doc(db, "bloccs", id, 'insights', today );
    const alltimeRef = doc(db, "bloccs", id, 'insights', 'alltime');
    const date = DateTime.now().c
    const week = DateTime.local(date.year,date.month,date.day).weekNumber
    //alert(week)
    try{
      await updateDoc(bloccRef, {
        'views': increment(1),  
        'date': new Date
      })
      await updateDoc(alltimeRef, {
        'views': increment(1),  
        'last_date': new Date
      })
      // console.log('stats updated!')
    }
    catch(e){
      // console.log(e)
      await setDoc(bloccRef, {
        views: 1,
        shares: 0,
        id: today,
        time:{
          week: 50,
          month: date.month,
          year: date.year,
          day:date.day,
        },
        links: {},
        traffic_from:{
          android: 0,
          ios: 0,
          web: 0
        },
        bloccID: id
        
      })
      await setDoc(alltimeRef, {
        views: 1,
        shares: 0,
        links: {},
        traffic_from:{
          android: 0,
          ios: 0,
          web: 0
        },
        bloccID: id
        
      })
    }
    
  }


  const updateShares = async () => {
    const bloccRef = doc(db, "bloccs", bloccid, 'insights', today );
    const alltimeRef = doc(db, "bloccs", bloccid, 'insights', 'alltime');

    try{
        await updateDoc(bloccRef, {
            "shares": increment(1),  
        })
        await updateDoc(alltimeRef, {
            "shares": increment(1)
        })
        // console.log('stats updated!')
    }
    catch(e){
        // console.log(e)
    }
    
  }

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
  }

  let share = () => {
    if (navigator.share) {
        navigator.share({
          title: 'Share this Goblocc',
          url: 'https://goblocc.com/' + blocc_id
        }).then(async () => {
          updateShares()
          console.log('goblocc shared')
        }).catch(console.error);
    } 
    else {
      alert("This device can't share!")
    }
  }

  let loadBlocc = async () => {
    const q = query(collection(db, "bloccs"), where("link", "==", blocc_id));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      
 
      if (doc.exists()) {
        setFound(true)

        const this_id = doc.id
        const data = doc.data()
        const newstructure = data.structure
        //console.log(data)
        // isProfileLink(data.link),
        isBloccId(doc.id)
        sessionStorage.setItem('blocc_id',doc.id)

        isFacebook(data.socials.facebook)
        isTiktok(data.socials.tiktok)
        isTwitter(data.socials.twitter)
        isInstagram(data.socials.instagram)

        isColor(data?.bgColor)
        isTransparent(data?.hasTransparency.state)
        isMainColor(data?.hasMainColor.color)
        this_hasBackgroud(data?.hasBackground.state)
        this_backgroundValue(data?.hasBackground.value)
        isProfileImage(data?.cover);
        isDescr(data?.descr)
       
       
        //isStack(data?.stack)
        
        //alert(transparent)
        if(data?.stack){
          let arr = data.structure
          let newArr = []
          let stack = []

          console.log(arr)
  
          for(var i = 0; i < arr.length; i++){
            if(arr[i].type !== 'imagecard'){
              newArr.push(arr[i])
            }
            else{
              //console.log(arr[i].type + ' <=========> ' + arr[i + 1].type)
              if(arr[i + 1] !== undefined && arr[i].type == 'imagecard' && arr[i + 1].type !== 'imagecard' && stack.length == 0){
                newArr.push(arr[i])
              }
              else if(arr[i + 1] !== undefined && arr[i].type == 'imagecard' && arr[i + 1]?.type == 'imagecard'){
                stack.push(arr[i])
              }
              else{
                console.log('hellooooooooooo')
                stack.push(arr[i])
                newArr.push(stack)
                stack = []
              }
            }
          }
  
          console.log('=======new Arr =========>',newArr)
         
          isStructure(newArr)

        
        }
        else isStructure(data.structure)

        updateStats(this_id,data?.owner_id)
        updateStatsDetailed(this_id)
        isDataLoaded(true)
      }
      
    });
   
  }

  useEffect(() => {
    loadBlocc()
  },[])

  useEffect(() => {
    console.log('screen size ==> ',screenSize)
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Cleanup the listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  const render = structure.map((data,index,elements) => {
    if(!data.length || data.length == undefined){
      return <Blocc key={data.id} id={data.id} Type={data.type} Link={data.link} Title={data.title} Transparent={transparent} backgroundColor={data.color} TextColor={data.color_type} Description={data.description} Source={data.source} MainColor={maincolor} onClick={() => {
        
        isFullImage(true)
        setImage(data.source)
        // isShowLinks(true)
      }}/>
    } 
    
    else{
      const renderstack = data.map((data,index,elements) => {
        return <div className='col-12' style={{marginRight:'5%'}}>
          <Blocc key={data.id} id={data.id} Type={data.type} Link={data.link} Title={data.title} Description={data.description} backgroundColor={data.color} Source={data.source} 
              onClick={() =>{
                
              isFullImage(true)
              setImage(data.source)
              
            }} 
          /></div>
      })
      return <div className='d-flex row-flex stacked' style={{marginBottom:15}}>{renderstack}</div>
    }

    
  })

  const renderAnchors = structure.map((data,index,elements) => {
    //console.log(data)
    if(data.type === 'anchorcard'){
      return (
        <a className='nostyle' href={`#${data.id}`}>
          <div className='anchorBtn' onClick={() => {setTimeout(() => {isShowLinks(false)}, 450)}}>{data.title}</div>
        </a>
      ) 
      }
  })

  
  if(!loaded){
    return (
      <div className="fullscreen transition" style={{zIndex:99,backgroundColor:dataloaded && !hasbackground? bgColor:'#212121',justifyContent:'center',alignItems:'center'}}>
        
        <div className='centered' style={{width:370,height:355}}>
          <Player
            autoplay
            speed={1.65}
            onEvent={event => {
              if (event === 'complete') isLoaded(true) // check event type and do something
            }}
            src="https://assets8.lottiefiles.com/packages/lf20_poqmycwy.json"
            style={{ height: '300px', width: '300px',margin:'auto',marginBottom:'20%'}}
          />
        </div>
       
        <div className='centered'  style={{width:50,height:45}}>
          <div style={{display:'grid',zIndex:2}}>
            <img width={50} src='./logo.png'/>
            <img width={50} src='./logotext.png' style={{marginTop:10}}/>
          </div>
        </div>
        
          <div style={{zIndex:0,opacity:hasbackground && dataloaded? 0.5:0}}>
            <div className='dim animate__animated animate__fadeIn' style={{zIndex:1}}/>
            <img src={backgroundValue? backgroundValue:'./bgplaceholder.png'} className="bgcoverPre animate__animated animate__fadeIn delay" alt="logo" />
          </div>   
        
        
      </div>
      
    )
  }

  if(!found){
    return(
      <div className="App" style={{backgroundColor:'#212121',width:'100%',height:'100vh'}}>

        <div className='centered animate__animated animate__fadeIn'>
          <div style={{width:50,height:45,margin:'auto'}}>
            <div className='animate__animated animate__fadeInUp' style={{display:'grid',zIndex:2}}>
              <img width={50} src='./logo.png'/>
              <img width={50} src='./logotext.png' style={{marginTop:10}}/>
            </div>
          </div>

          <DotLottieReact
            loop
            autoplay
            speed={1.65}
            src="https://lottie.host/22ea166b-83a4-4fc7-8c07-52d7342fe38f/Tk9e771l7W.lottie"
            style={{ height: '300px', width: '300px',margin:'auto'}}
          />

          <p className='mb-0 mbl' style={{color:'white'}}>Well this is awkward...The page you're looking for could not be found.</p>
          <p className='mb-0 mbl' style={{color:'white'}}>The good news is you can create your own custom Blocc !</p>
          <p className='mbl' style={{color:'white'}}>Soon...</p>
        </div>
        
      </div>
    )
  }

  return (
    <div className="App" style={{backgroundColor:hasbackground? 'transparent': bgColor}}>
      
      <div className="modal fade" id='fullMedia'>
        <span 
          className="material-icons menu" 
          data-bs-dismiss="modal"
          style={{
            position: 'absolute',
            top: '2%',
            right:'5%',
            backgroundColor: 'black',
            borderRadius: 100,
            padding: 5
          }}
        >
          close
        </span>
        <div className="modal-dialog modal-dialog-centered">
          
          <img 
            style={{
              width:'100%',
              borderRadius:16
            }}
            src={currentimage} 
          />
        </div>
      </div>

      {showLinks &&
        <div className="fullscreen">

          <span className="material-icons menu" style={{position:'absolute',top: '2%',backgroundColor:'black',borderRadius:100,padding:5}} onClick={() => isShowLinks(false)}>close</span>
          <span style={{position:'absolute',top: '2%',left:'10%',backgroundColor:'black',borderRadius:100,padding:9,fontSize:21,width:40,height:40}} className="menu material-icons animate__animated animate__fadeIn" onClick={() => share()}>share</span>
        
          
          <div style={{width:345,margin:'auto',height:'100%',position:'relative',marginTop:115}}>
            
            
            <h4 className='text-white' style={{marginBottom:25}}>Jump To</h4>

            <div className='animate__animated animate__fadeIn' style={{justifyContent:'center',alignItems:'center',marginBottom:15,flexDirection:'row',borderBottomColor: 'white',borderBottomWidth:1}}>
              {facebook &&
                <i onClick={() => window.open(facebook, '_blank')} className="custom-icons inmenu fa-brands fa-facebook-f"></i>
              }
              {twitter &&
                <i onClick={() => window.open(twitter, '_blank')} className="custom-icons inmenu fa-brands fa-twitter"></i>
              }
              {instagram &&
                <i onClick={() => window.open(instagram, '_blank')} className="custom-icons inmenu fa-brands fa-instagram"></i>
              }
              {tiktok &&
                <i onClick={() => window.open(tiktok, '_blank')} className="custom-icons inmenu fa-brands fa-tiktok"></i>
              }
              
            </div> 

            <hr style={{borderWidth:2,color:'white'}} />
            <>{renderAnchors}</>
          </div>
          
        </div>
      }
    
      {screenSize.width < 600?
        (
          <div className='top-cover d-flex animate__animated d-none'>
            <img src={profileimage} className="blocc-cover-reduced topz animate__animated animate__fadeIn" alt="logo" />
            <p className={`descrText-reduced animate__animated animate__fadeIn topz ${hasbackground? 'text-white':''}`}>{descr}</p>

            <span className="material-icons menu" onClick={() => {
              // console.log('clicked!!!')
              isShowLinks(true)
            }}>menu</span>
          </div>
        )

        :

        (
          <div className='top-cover desktop animate__animated animate__fadeInLeft d-none'>
            <div className=' d-flex'>
              <img src={profileimage} className="blocc-cover-reduced topz animate__animated animate__fadeIn" alt="logo" />
              <p className={`descrText-reduced animate__animated animate__fadeIn topz ${hasbackground? 'text-white':''}`}>{descr}</p>
            </div>

            <hr/>
            
            {<>{renderAnchors}</>}
          </div>
        )
      }
      
      
      {hasbackground &&
        <>
          <div className='dim'></div>
          {screenSize.width > 600?
            (
              <>
                <img src={backgroundValue} className="background-blur" alt="background"></img>
                <img src={backgroundValue} className="foreground-image fade-image animate__animated animate__fadeIn" alt="foreground"></img>
              </>
            )

            :

            (
              <img src={backgroundValue} className="bgcover" alt="logo" />
            )
          }
          
          
        </>   
      }
      <header className="App-header" style={{zIndex:99}}>
        
        <span id='shareIcon' className="material-icons animate__animated animate__fadeIn" 
          onClick={() => share()}
        >share</span>

        <img src={profileimage} className="blocc-cover topz animate__animated animate__fadeIn topblock" alt="logo" />
        <p className={`descrText animate__animated animate__fadeIn topz topblock ${maincolor == 'white'? 'text-white':'text-black'}`}>{descr}</p>

        <div className='topblock animate__animated animate__fadeIn' style={{justifyContent:'center',alignItems:'center',marginBottom:15,zIndex:99999}}>
          {facebook &&
            <i className="custom-icons fa-brands fa-facebook-f" onClick={() => window.open(facebook, '_blank')} />
          }
          
          {twitter &&
            <i className="custom-icons fa-brands fa-twitter" onClick={() => window.open(twitter, '_blank')}/>
          }

          {instagram &&
            <i className="custom-icons fa-brands fa-instagram" onClick={() => window.open(instagram, '_blank')}/>
          }

          {tiktok &&
            <i className="custom-icons fa-brands fa-tiktok" onClick={() => window.open(tiktok, '_blank')}/>
          }
        </div>
      

        <div className={` animate__animated animate__fadeIn structureContainer topz ${hasbackground? 'text-white':''}`}>{render}</div>
      </header>
    </div>
  );
}

export default App;
